<template>
  <div>
    <b-card class="p-2">
      <!-- form -->
      <validation-observer ref="addfoodForm">
        <b-form @submit.prevent="submitForm">
          <h3 class="mb-2">Add Specifications</h3>
          <b-row>
            <!-- first name -->
            <b-col cols="4">
              <b-form-group label="Name" label-for="code">
                <validation-provider
                  #default="{ errors }"
                  name=" Name"
                  rules="required"
                >
                  <b-form-input
                    id="code"
                    v-model="addItem.name"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Price" label-for="Name">
                <validation-provider
                  #default="{ errors }"
                  name=" Name"
                  rules="required"
                >
                  <b-form-input
                    id="Name"
                    v-model="addItem.price"
                    placeholder="Price"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group label="Category" label-for="Category">
                <validation-provider
                  #default="{ errors }"
                  name="Category"
                  rules="required"
                >
                  <b-form-select
                    v-model="addItem.type"
                    :options="options"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12" class="text-right">
              <b-button type="submit" variant="primary" class="mr-1 mt-1">
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card class="">
      <!-- form -->
      <div>
        <div>
          <b-table striped hover :items="items" :fields="fields">
            <template #cell(Sl.no)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(action)="data">
              <b-badge
                variant="danger"
                class="ml-2 hover-pointer"
                @click="initdeletefood(data.item.id)"
              >
                <feather-icon icon="TrashIcon" class="" />
              </b-badge>
            </template>
          </b-table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BCard,
  BListGroup,
  BListGroupItem,
  BCardText,
  BFormTextarea,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BTable,
  BButton,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";

import store from "@/store/index";
import { ref, onUnmounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import usefoodList from "./usefoodList";
import { required, integer } from "@validations";
import foodStoreModule from "../foodStoreModule";
import axios from "@axios";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BBadge,
    vSelect,
    VueGoodTable,
    BTable,
    BFormFile,
    BFormTextarea,
    BFormSelect,
    BCard,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    required,
    integer,
  },
  data() {
    return {
      fields: [
        "Sl.no",
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "price",
          label: "price",
          sortable: true,
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      items: [],
      addItem: {
        name: null,
        price: null,
        type: null,
        item_id: this.$route.params.id,
      },
      options: [
        { value: null, text: "Please select an option" },
        { value: "Size", text: "Size" },
        { value: "Density", text: "Density" },
      ],
    };
  },
  async mounted() {
    const promises = [axios.get(`admin/get/spec/${this.$route.params.id}`)];
    const allResponses = await Promise.all(promises);
    this.items = allResponses[0].data.data;
  },

  methods: {
    initdeletefood(id) {
      axios
        .post("admin/post/delspec/" + id + "")
        .then(function (response) {
          if (response.data.result == 1) {
            setTimeout(() => {
              location.reload();
            }, 200);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    submitForm() {
      this.$refs.addfoodForm.validate().then(async (success) => {
        if (success) {
          await axios
            .post("admin/post/addspec", this.addItem)
            .then(function (response) {
              if (response.data.result == 1) {
                setTimeout(() => {
                  location.reload();
                }, 200);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
  },
  setup() {
    // Register module
    const APP_STORE_MODULE_NAME = "master-food";

    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, foodStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    return {};
  },
};
</script>
